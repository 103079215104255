export const languages = [
    {
      code: 'en',
      name: 'ENG',
      list: 'ENGLISH',
      country_code: 'gb',
    },
    {
      code: 'th',
      name: 'TH',
      list: 'THAI',
      country_code: 'th',
    },
    {
      code: 'cn',
      name: 'CN',
      list: 'Chinese',
      country_code: 'cn',
    },
    // {
    //   code: 'ru',
    //   name: 'RU',
    //   list: 'Russia',
    //   country_code: 'ru',
    // },
  ]

