import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { apiLink } from "../constants/DataPublic";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from 'react-responsive'

const PaymentTruewallet = (props) => {
    const { language } = props

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 561px)' })

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    const { t } = useTranslation();
    const navigate = useNavigate()

    const [phoneNo, setPhoneNo] = useState("")
    const [loading, setLoading] = useState(false)
    const [reqiurePhone, setRequirePhone] = useState('')

    useEffect(() => {
        setTimeout(() => {
            setLoading(true)
        }, 500)
    }, [])

    const handleSubmit = async () => {

        let valid = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNo)

        if (phoneNo !== '' && valid) {
            setLoading(false)
            const requestOptions = {
                method: "POST",
                headers: { "accept-language": language, 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    "ConfirmationNo": user.confirmationNo,
                    "UserCode": user.userCode,
                    "paymentType": 'truemoney',
                    "PhoneNo": phoneNo,
                    "InvoiceNumber": user.InvoiceNumber
                }),
            };
            // ​/afcl​/Package​/Omise​/InternetBanking
            await fetch(apiLink + '/afcl/Package/Omise/InternetBanking', requestOptions).
                then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    if (data["Success"]) {
                        const items = data["Result"];
                        setLoading(true)
                        window.location.href = items["WebPaymentUrl"];
                    } else {
                        setLoading(true)
                        console.log("bad request", data["Result"])
                    }
                })
        } else {
            handleChange()
        }
    }

    const handleChange = (e) => {
        console.log("phoneNo", phoneNo)
        if (!/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNo)) {
            let word = phoneNo !== '' ? 'wrong_mobile_pattern' : 'require_mobile'
            setRequirePhone(word)
        } else {
            setRequirePhone('')
        }
    }

    useEffect(() => {
        if (phoneNo !== '') {
            const timeOutId = setTimeout(() => handleChange(), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [phoneNo]);


    const cancel = () => {
        navigate('/paymentOmise')
    }

    return (
        <div className="page homepage" style={{ height: '720px' }}>
            {!loading && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <div className="section pt-5">
                <div className="container d-flex justify-content-center">
                    <div class="col-10 col-md-8 col-lg-6" style={{ paddingBottom: '20px' }}>
                        <div id="credit_card" class="card text-center">
                            <div class="card-body">
                                <div className="row" style={{ height: '45px' }}>
                                    <div className="col-6 h-100">
                                        <img src="https://cdn.omise.co/assets/screenshots/series-b/omise-logo.jpg" alt="omise" width={100} />
                                    </div>
                                    <div className="col-6 h-100">
                                        <img src="https://i0.wp.com/www.9tana.com/wp-content/uploads/2019/06/truewallet.jpg?fit=600%2C290&ssl=1&resize=1280%2C720" alt="omise" width={100} />
                                    </div>
                                </div>
                                <hr />
                                <h2 className="font-30 lh-1 mb-5" style={{
                                    width: '100%',
                                    height: '100%',
                                    color: '#0e315c'
                                }}>
                                    True Money Wallet
                                </h2>
                                <p class="card-text">{t('enter_your_mobile_number')}</p>
                                <div className="col-8 mb-5" style={{ margin: 'auto' }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="expiration_month"
                                        placeholder={t('telephone_no')}
                                        maxLength="11"
                                        style={{ fontWeight: 'normal', border: reqiurePhone !== '' ? '1px solid #ca4d4d' : '' }}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => { setPhoneNo(e.target.value); }}
                                    />
                                    <span className="text-danger text-left">{t(reqiurePhone)}</span>
                                </div>
                                <div className="row col-12 col-sm-8 mb-3" style={{ margin: 'auto' }}>
                                    <div className="col-6">
                                        <button type="button" className="btn btn-blue" style={{ fontSize: isTabletOrMobile ? '11px' : '' }} onClick={handleSubmit}>
                                            {t('confirm')}
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="btn btn-blue-reverse" style={{ fontSize: isTabletOrMobile ? '11px' : '' }} onClick={cancel}>
                                            {t('cancel')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentTruewallet;