import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

// import logo from "./img/logo.png";

import logo from "./img/logo-columbia.png";
import logoBackToHome from "./img/back-to-homepage.png";

// import logo from "./img/logo-back-home.svg";
import LogoKohTalu from "./img/LogoKohTalu.png";
import logoxl from "./img/logo-xl.png";
import PicProfile from "../component/img/picuser2.png";
import { ReactComponent as User } from "./img/user.svg";
import { ReactComponent as Cart } from "./img/cart.svg";
import { ReactComponent as Hamburger } from "./img/hamburger.svg";
import { baseLink, apiLink, coreApi } from "../constants/DataPublic";
import { useGlobalState } from '../global'
import { languages } from "../constants/Language";

import "./css/style.css";

const Navbar = (props) => {

  const { cartItems } = props

  const [routePath, setRoutePath] = useGlobalState('route');
  const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');
  const [loadCampaign, setLoadCampaign] = useGlobalState('loadCampaign');
  const [login, setLogin] = useState(false)
  const [toLink, setToLink] = useState('#')
  const [cItems, setCItems] = useState("")
  const [ticketlink, setTicketlink] = useState()
  const [ticket, setTicket] = useState()
  const [cart, setCart] = useState([]);

  const navigate = useNavigate()

  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  let itCrossCam = sessionStorage.getItem('itCrossCampaign');
  itCrossCam = JSON.parse(itCrossCam);

  useEffect(() => {
    console.log("itCrossCam", itCrossCam)
    if (myCart != null && myCart.length > 0) {
      setCItems(myCart.length.toString())
    }
  }, [myCart])

  useEffect(() => {
    if (cartItems > 0 && cartItems < 100) {
      setCItems(cartItems)
    } else if (cartItems > 100) {
      setCItems("99+")
    } else if (myCart != null && myCart.length > 0) {
      setCItems(myCart.length.toString())
    } else {
      setCItems("")
    }
  }, [cartItems, myCart])

  const showUserLogin = () => {
    user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    if (user != null) {
      setLogin(user.isLogin)

      if (user.isLogin) {
        setToLink(baseLink === '' ? routePath + '/#' : baseLink + routePath + '#')
      } else {
        setToLink(baseLink + routePath + "/signin")
      }
    } else {
      setLogin(false)
      setToLink(baseLink + routePath + "/signin")
    }
  }

  async function setLogout(e) {
    e.preventDefault()

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    let headers = {
      "UserId": user.userId,
      "Email": user.email,
      "DeviceKey": ""
    }
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(headers)
    };
    await fetch(coreApi + '/afcl/Account/logout', requestOptions)
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        const items = data;

        if (items['ok']) {

          sessionStorage.removeItem('UserLogin')
          sessionStorage.removeItem('MyCart')
          setLogin(false)
          setToLink(baseLink + routePath + "/signin")
          setCItems("")
          props.setUserCode("")
          props.userLogout(true)
          if (itCrossCam) {
            window.location.href = '/'
          } else {
            navigate("/")
          }
        }
      })
      .catch(error => {
        console.log("Error: ", error)
      });
  }

  const goToCart = () => {
    myCart = sessionStorage.getItem('MyCart')
    myCart = JSON.parse(myCart)
    if (myCart != null && myCart.length > 0) {
      navigate("/ticket/" + myCart[0].PackageCode + "/5")
    }
  }

  const onGetticket = async (item, isEdit) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': currentLanguageCode },
    };
    // let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
    const res = await fetch(apiLink + '/afcl/Package/Ticket', requestOptions);
    res
      .json()
      .then((res) => {
        let tic = res['Result']
        setTicket(res['Result']);
        let aIndex
        if (tic != null) {
          aIndex = tic.Packages.find((e) => {
            return e.PackageCode === 'AWPK001';
          });
        }
        let netP
        if (aIndex.IsDiscount) {
          netP = aIndex.PackagePrice - aIndex.DiscountPrice
        } else {
          netP = aIndex.PackagePrice
        }

        let data = {
          PackageCode: aIndex.PackageCode,
          PackageName: aIndex.PackageName,
          ItemCode: "",
          ItemName: "",
          Qty: 1,
          Tax: 0,
          ItemPrice: netP,
          ItemDiscount: aIndex.IsDiscount ? aIndex.DiscountPrice : 0,
          PriceName: aIndex.PriceName,
          PriceUnit: '',
          TotalPrice: netP,
          VisitDate: null,
          VisitTime: '',
          VisitLocation: '',
          step: aIndex.StepId,
          status: 'normal'
        }
        onAddToCart(data, false)
      })
      .catch((error) => {
        console.log(error);
      });

  }


  const onAddToCart = async (item, isEdit) => {

    myCart = sessionStorage.getItem('MyCart')
    myCart = JSON.parse(myCart)

    let aIndex = -1

    if (myCart != null) {
      aIndex = myCart.findIndex(e => { return e.PackageCode === item.PackageCode })
    } else {
      myCart = []
    }

    if (aIndex === -1) {
      if (item.Qty > 0) {
        myCart = [...myCart, item]
        setCart(myCart)
        window.location.href = baseLink + 'ticket/AWPK001/1'
      }
    } else {
      let preQty = myCart[aIndex].Qty
      let newQty = preQty + 1

      const editCart = myCart.map(e => {
        if (e.PackageCode === item.PackageCode && e.ItemCode === item.ItemCode) {
          return { ...e, Qty: newQty, VisitDate: item.VisitDate, VisitLocation: item.VisitLocation, VisitTime: item.VisitTime, status: 'normal' }
        }
        return e
      })
      setCart(editCart)
      window.location.href = baseLink + 'ticket/AWPK001/1'
    }

  }

  useEffect(() => {
    if (cart.length > 0) sessionStorage.setItem('MyCart', JSON.stringify(cart));
  }, [cart]);

  const currentLanguageCode = cookies.get('i18next') || 'th'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    props.getLanguage(currentLanguageCode)
    if (currentLanguageCode === 'cn' && window.location.pathname.split('/')[1] == '') {
      navigate('/16two')
    }
  }, [currentLanguageCode])

  return (
    <>
      {inactiveCam ?
        <div style={{ pointerEvents: inactiveCam ? 'none' : 'inherit' }}>
          <nav className="fixed-top d-block d-lg-none ">
            <div className="container-fluid navi">
              <div className="row justify-content-between align-items-center">
                <div className="col-4 ">
                  <img src={logo} alt="" className="logo" />
                </div>
                <div className="col-8 text-end">
                  <div className="row navmain">
                    <div className="col-3 p-0">
                      <div className="col-nav border-right border-left">
                        <div className="wrapper text-center ">

                          <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              {currentLanguage.name}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              {languages.map(({ code, name, list, country_code }) => (
                                <li key={country_code}>
                                  <span style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }}></span>
                                  {list}
                                </li>
                              ))}
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="col-3 p-0">
                      <div className="col-nav border-right">
                        <div className="wrapper ">

                          <div className="dropdown companymenuli">
                            <span className="mb-0">
                              <User className="icon" />
                            </span>

                            {/* {login && (
                              <ul className="dropdown-menu text-center py-4 px-3" aria-labelledby="dropdownMenuButton">
                                <li><img src={logo} className="logo-popup" /></li>
                                <li className="my-3">
                                  <img src={user.photo === '' ? PicProfile : user.photo} alt="" className="profilepic-navber" /></li>
                                <li className="mb-4">{user.fName + ' ' + user.lName}</li>
                                <li className="mb-2">
                                  <Button className="btn-changepw text-capitalize btn-light btn-shadow">{t('change_passcode')}</Button>
                                </li>
                                <li><Button className="btn-logout text-capitalize btn-success" onClick={setLogout}>{t('logout')}</Button></li>
                              </ul>
                            )} */}

                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="col-3 p-0">
                      <div className="col-nav border-right">
                        <div className="wrapper ">

                          <div className="mb-0 open-cart">
                            <Cart className="icon" />
                          </div>
                          <div>{cItems != "" && (<span className="icon-button-badge">{cItems}</span>)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 p-0 bg-black color-white">
                      <div className="col-nav">
                        <div className="wrapper ">
                          <a
                            href="#"
                            className="mb-0"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarTogglerDemo03"
                            aria-controls="navbarTogglerDemo03"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                          >
                            <Hamburger className="icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navmain">

                <li className="nav-item">
                  <div className="col-nav">
                    <div className="wrapper ">
                      {t('tickets')}
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="col-nav">
                    <div className="wrapper text-center ">
                      <p className="mb-1 font-8 color-grey">{t('opening_hours')}</p>
                      <p className="mb-0 font-14 weight-700">{t('time_opening')}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>

          <nav className="navbar navbar-expand-lg fixed-top d-none d-lg-block">
            <div className="container-fluid ps-4">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo03"
                aria-controls="navbarTogglerDemo03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <img src={logo} className="logo" />
              <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navmain">

                  <li className="nav-item">
                    <div className="col-nav p-0">
                      <div className="wrapper ">
                        {/* onClick={() => {
                     addToCart(
                       {
                         PackageCode: packageCard.PackageCode,
                         PackageName: packageCard.PackageName,
                         ItemCode: "",
                         ItemName: "",
                         Qty: 1,
                         Tax: 0,
                         ItemPrice: netPrice,
                         ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
                         PriceName: packageCard.PriceName,
                         PriceUnit: '',
                         TotalPrice: netPrice,
                         VisitDate: null,
                         VisitTime: '',
                         VisitLocation: '',
                         step: packageCard.StepId,
                         status: 'normal'
                       }, false
                     )
                   }} */}
                        <a className="mb-0 ticket">
                          {t('tickets')}
                        </a>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-right">
                      <div className="wrapper text-center ">
                        <p className="mb-1 font-8 color-grey">{t('opening_hours')}</p>
                        <p className="mb-0 font-14 weight-700">
                          {t('time_opening')}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-right">
                      <div className="wrapper text-center ">

                        <div className="dropdown">
                          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {currentLanguage.name}
                          </button>
                          {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {languages.map(({ code, name, list, country_code }) => (
                              <li key={country_code}>
                                <span style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }}></span>
                                {list}
                              </li>
                            ))}
                          </ul> */}
                        </div>

                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-right">
                      <div className="wrapper ">
                        <li className="dropdown companymenuli">
                          <User className="icon" />
                        </li>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-">
                      <div className="wrapper ">

                        <span className="mb-0 p-3 open-cart" style={{ cursor: "pointer" }}>
                          <Cart className="icon" />
                          {cItems != "" && (<span className="icon-button-badge">{cItems}</span>)}
                        </span>

                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        :
        <div>
          <nav className="fixed-top d-block d-lg-none ">
            <div className="container-fluid navi">
              <div className="row justify-content-between align-items-center">
                <div className="col-3 " style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }} >
                  <a className="navbar-brand" href='https://columbiapicturesaquaverse.com/en/'>
                    <img src={logoBackToHome} style={{ width: '90%' }} alt="" className="logo" />
                  </a>
                </div>
                <div className="col-3 " style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }} >
                  <a className="navbar-brand" href={itCrossCam ? baseLink + '/' : (baseLink + routePath == "" ? '/' : baseLink + routePath)}>
                    <img src={logo} style={{ width: '90%' }} alt="" className="logo" />
                  </a>
                </div>
                <div className="col-6 text-end">
                  <div className="row navmain">
                    <div className="col-3 p-0">
                      <div className="col-nav border-right border-left">
                        <div className="wrapper text-center ">

                          <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              {currentLanguage.name}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              {languages.map(({ code, name, list, country_code }) => (
                                <li key={country_code}>
                                  <a href="#" className={classNames('dropdown-item', { disabled: currentLanguageCode === code, })} onClick={() => { i18next.changeLanguage(code) }}>
                                    <span style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }}></span>
                                    {list}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="col-3 p-0">
                      <div className="col-nav border-right">
                        <div className="wrapper ">

                          <div className="dropdown companymenuli">
                            <span className="mb-0" onMouseOver={showUserLogin}
                              style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }}
                              onClick={() => {
                                if (!login)
                                  navigate("/signin")
                              }}>
                              <User className="icon" />
                            </span>

                            {login && (
                              <ul className="dropdown-menu text-center py-4 px-3" aria-labelledby="dropdownMenuButton">
                                <li><img src={logo} className="logo-popup" /></li>
                                <li className="my-3">
                                  <img src={user.photo === '' ? PicProfile : user.photo} alt="" className="profilepic-navber" /></li>
                                <li className="mb-4">{user.fName + ' ' + user.lName}</li>
                                <li className="mb-2">
                                  <a href={baseLink + routePath + "/changepw"}>
                                    <Button className="btn-changepw text-capitalize btn-light btn-shadow">{t('change_passcode')}</Button>
                                  </a>
                                </li>
                                <li><Button className="btn-logout text-capitalize btn-success" onClick={setLogout}>{t('logout')}</Button></li>
                              </ul>
                            )}

                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="col-3 p-0">
                      <div className="col-nav border-right">
                        <div className="wrapper ">

                          <div className="mb-0 open-cart" onClick={goToCart}>
                            <Cart className="icon" />
                          </div>
                          <div>{cItems != "" && (<span className="icon-button-badge">{cItems}</span>)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 p-0 bg-black color-white">
                      <div className="col-nav">
                        <div className="wrapper ">
                          <a
                            href="#"
                            className="mb-0"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarTogglerDemo03"
                            aria-controls="navbarTogglerDemo03"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                          >
                            <Hamburger className="icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navmain">

                <li className="nav-item">
                  <div className="col-nav">
                    <div className="wrapper " style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }}>
                      {/* ticket/AWPK001/1 */}
                      {routePath == '' ?
                        <a href={baseLink + '/ticket/AWPK001/1'} className="mb-0 ticket">
                          {t('tickets')}
                        </a>
                        :
                        <a href={itCrossCam ? baseLink + '/' : baseLink + routePath} className="mb-0 ticket">
                          {t('tickets')}
                        </a>
                      }

                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="col-nav">
                    <div className="wrapper text-center ">
                      <p className="mb-1 font-8 color-grey">{t('opening_hours')}</p>
                      <p className="mb-0 font-14 weight-700">{t('time_opening')}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>

          <nav className="navbar navbar-expand-lg fixed-top d-none d-lg-block">
            <div className="container-fluid ps-4">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo03"
                aria-controls="navbarTogglerDemo03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <a style={{ pointerEvents: loadCampaign ? 'inherit' : 'none', width: '185px' }} className="navbar-brand" href='https://columbiapicturesaquaverse.com/en/'>
                <img src={logoBackToHome} style={{ width: '100%', padding: '5px' }} className="logo" />
              </a>
              <a style={{ pointerEvents: loadCampaign ? 'inherit' : 'none', width: '185px' }} className="navbar-brand" href={itCrossCam ? baseLink + '/' : baseLink + routePath == "" ? '/' : baseLink + routePath}>
                <img src={logo} style={{ width: '100%', padding: '0' }} className="logo" />
              </a>
              <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navmain">

                  <li className="nav-item">
                    <div className="col-nav p-0">
                      <div className="wrapper " style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }} >
                        {/* onClick={() => {
                          addToCart(
                            {
                              PackageCode: packageCard.PackageCode,
                              PackageName: packageCard.PackageName,
                              ItemCode: "",
                              ItemName: "",
                              Qty: 1,
                              Tax: 0,
                              ItemPrice: netPrice,
                              ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
                              PriceName: packageCard.PriceName,
                              PriceUnit: '',
                              TotalPrice: netPrice,
                              VisitDate: null,
                              VisitTime: '',
                              VisitLocation: '',
                              step: packageCard.StepId,
                              status: 'normal'
                            }, false
                          )
                        }} */}
                        {routePath == '' ?
                          <a href={baseLink + '/ticket/AWPK001/1'} className="mb-0 ticket">
                            {t('tickets')}
                          </a>
                          :
                          <a href={itCrossCam ? baseLink + '/' : baseLink + routePath} className="mb-0 ticket">
                            {t('tickets')}
                          </a>
                        }
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-right">
                      <div className="wrapper text-center ">
                        <p className="mb-1 font-8 color-grey">{t('opening_hours')}</p>
                        <p className="mb-0 font-14 weight-700">
                          {t('time_opening')}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-right">
                      <div className="wrapper text-center ">

                        <div className="dropdown">
                          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {currentLanguage.name}
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {languages.map(({ code, name, list, country_code }) => (
                              <li key={country_code}>
                                <a href="#" className={classNames('dropdown-item', { disabled: currentLanguageCode === code, })} onClick={() => { i18next.changeLanguage(code) }}>
                                  <span style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }}></span>
                                  {list}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>

                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-right">
                      <div className="wrapper ">
                        <li className="dropdown companymenuli">
                          <a style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }} href={toLink} className="mb-0" onMouseOver={showUserLogin}>
                            <User className="icon" />
                          </a>
                          {login && (
                            <ul className="dropdown-menu box-logout submenu" aria-labelledby="dropdownMenuButton">
                              <li><img src={logoxl} className="logo-popup" style={{ width: "55%" }} /></li>
                              <li className="box-text-signin"><img src={user.photo === '' ? PicProfile : user.photo} className="logo profilepic" /></li>
                              <li className="box-text-signin">{user.fName + ' ' + user.lName}</li>
                              <li className="mb-3">
                                <a href={baseLink + routePath + "/changepw"}>
                                  <Button className="text-capitalize btn-light btn-shadow">{t('change_passcode')}</Button>
                                </a>
                              </li>
                              <li><Button className="text-capitalize btn-success" onClick={setLogout}>{t('logout')}</Button></li>
                            </ul>
                          )}
                        </li>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-">
                      <div className="wrapper ">

                        <span className="mb-0 p-3 open-cart" style={{ cursor: "pointer" }} onClick={goToCart}>
                          <Cart className="icon" />
                          {cItems != "" && (<span className="icon-button-badge">{cItems}</span>)}
                        </span>

                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      }

    </>
  );
};

export default Navbar;
