import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { baseLink } from "./constants/DataPublic";
import cookies from "js-cookie";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { clientId } from "./constants/DataPublic";
// import ReactGA from 'react-ga';
// import ReactGA from 'react-ga4';

// ReactGA.initialize("G-5WBGCW6XJ1");

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "th", "cn"],// "ru"
    fallbackLng: "th",
    lng: cookies.get("i18next") || "th",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    react: { useSuspense: false },
    backend: {
      loadPath: baseLink + "/assets/locales/{{lng}}/translation.json",
    },
  });
// lng: cookies.get('i18next') || 'th',

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
